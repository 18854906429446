<template>
  <div>
    <b-overlay :show="showOverlay" rounded="sm">
      <b-card no-body>
        <div class="card-header">
          <!-- Title & SubTitle -->
          <div>
            <b-card-title></b-card-title>
            <b-card-sub-title></b-card-sub-title>
          </div>

          <!-- Card Actions -->
          <div class="heading-elements">
            <ul class="list-inline mb-0">
              <li></li>
              <li></li>
            </ul>
          </div>
        </div> 

        <!-- Card Body  -->
        <b-card-body>
          <ag-grid-vue
            style="width: 100%; height: 900px"
            class="ag-theme-alpine"
            :columnDefs="columnDefs"
            :rowData="rowData"
            :pinnedTopRowData="pinnedTopRows"
          ></ag-grid-vue>
        </b-card-body>
      </b-card>

      <b-card no-body>
        <div class="card-header">
          <!-- Title & SubTitle -->
          <div>
            <b-card-title>Comparación de Función Objetivo</b-card-title>
            <b-card-sub-title></b-card-sub-title>
          </div>

          <!-- Card Actions -->
          <div class="heading-elements">
            <ul class="list-inline mb-0">
              <li></li>
              <li></li>
            </ul>
          </div>
        </div> 

        <!-- Card Body  -->
        <b-card-body>
          <ag-grid-vue
            style="width: 100%; height: 900px"
            class="ag-theme-alpine"
            :columnDefs="columnDefs2"
            :rowData="rowData2"
            :pinnedTopRowData="pinnedTopRows2"
          ></ag-grid-vue>
        </b-card-body>
      </b-card>


      <!--
      <bruna-element-loader
        :dataUrl="compareOptimizationsMetricsParallel"
        ref="bel0"
      >
      </bruna-element-loader>

      <bruna-element-loader :dataUrl="compareOptimizationsMetrics">
      </bruna-element-loader>
-->
      <template #overlay>
        <div class="text-center">
          <b-spinner label="Loading..." />
          <p id="cancel-label">{{ $t('Cargando Resultados...') }}</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template> 
  
  <script>


import BrunaElementLoader from '@/views/brunacomponents/BrunaElementLoader.vue'

import { AgGridVue } from "ag-grid-vue";

import axios from '@axios'

import
{
  BTabs,
  BTab,
  BOverlay,
  BSpinner
} from "bootstrap-vue";

var qs = require('qs');
import useApiServices from '@/services/useApiServices.js';


export default {
  data()
  {
    let ids = this.$route.query.ids;

    console.log(ids)

    let query = qs.stringify({ run_ids: ids })

    console.log(query)




    return {
      showOverlay: false,
      compareOptimizationsMetrics: useApiServices.compareOptimizations.compareOptimizationsMetrics + "?" + query,
      compareOptimizationsMetricsParallel: useApiServices.compareOptimizations.compareOptimizationsMetricsParallel + "?" + query,
      compareOptimizationsFOB: useApiServices.compareOptimizations.compareOptimizationsFOB + "?" + query,

      columnDefs: [],
      rowData: [],
      pinnedTopRows: [],

      columnDefs2: [],
      rowData2: [],
      pinnedTopRows2: []
      


    };
  },

  mounted()
  {

    let ids = this.$route.query.ids;

    console.log(ids)

    let query = qs.stringify({ run_ids: ids })

    console.log(query)



    axios.request({
      method: "get",
      url: useApiServices.compareOptimizations.compareOptimizationsMetricsTable + "?" + query,
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) =>
      {

        this.columnDefs = response.data.columnDefs;
        this.rowData = response.data.rowData;
        this.pinnedTopRows = response.data.pinnedTopRows;

      })

      axios.request({
      method: "get",
      url: useApiServices.compareOptimizations.compareOptimizationsFOB + "?" + query,
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) =>
      {

        this.columnDefs2 = response.data.columnDefs;
        this.rowData2 = response.data.rowData;
        this.pinnedTopRows2 = response.data.pinnedTopRows;

      })




  },

  created()
  {




  },

  components: {
    BTabs,
    BTab,
    BOverlay,
    BSpinner,
    BrunaElementLoader,
    AgGridVue



  },

  methods: {




  },

};
  </script>

<style lang="scss" scoped>
@import "~ag-grid-community/dist/styles/ag-grid.css";
//@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>
  
<style >
.btn-padding {
  margin-right: 48px;
}

.m-estadisticas {
  margin-left: 20px;
  margin-bottom: 1%;
  margin-top: 1%;
}
.icon-plan {
  padding-top: 5%;
}


.ag-row-pinned {
background-color: #F0E68C !important;
}


</style>
  

<style>
div.ag-watermark {
    display: none !important;
}


</style>
